<template>
  <div>
    <v-dialog
      v-model="formOpened"
      content-class="j_modal"
      hide-overlay 
      fullscreen 
      persistent
      transition="slide-full-modal-transition"
      >
      <v-card>
        <div class="modal__header full_title pd_left_3">
          <button class="hamberger_menu" type="button"  @click="onAction('cancel')">
            <v-icon>mdi-menu</v-icon>
          </button>
          <span>Format Summary Table <span class="sub_title">{{ `${idxNum} ${subTitle}` }}</span></span>
        </div>

        <v-card-text :class="classTab">
          <!-- <v-form v-model="valid" ref="form"> -->
            <v-container>
              
              <v-card-actions class="modal_toolbar">
                <div class="tool_menus">
                  <div>
                    <div data-summary-quickbtn="summary_single" title="single" :class="chartItem.ChartType == __C_.TYPE_CODE_SINGLE ? 'selected': ''" @click="onChartType(__C_.TYPE_CODE_NORMAL)"></div>
                  </div>
                  <div>
                    <div data-summary-quickbtn="summary_multi" title="multi" :class="chartItem.ChartType == __C_.TYPE_CODE_MULTI ? 'selected': ''" @click="onChartType(__C_.TYPE_CODE_NORMAL)"></div>
                  </div>
                  <div>
                    <div data-summary-quickbtn="summary_timetable" title="timetable" :class="chartItem.ChartType == __C_.TYPE_CODE_TIMETABLE ? 'selected': ''" @click="onChartType(__C_.TYPE_CODE_NORMAL)"></div>
                  </div>
                </div>
              </v-card-actions>
              <div class="modal_dark_btn extra_properties_summary">
                <j-button
                  class="type01 sky  type_full "
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('save')"
                >Save</j-button>
                <j-button v-if="modeMod" class="type01 delete  type_full" @click="onAction('delete')">Delete</j-button>
                <div class="check_wrapper">
                  <v-checkbox v-model="update" :label="'Update'"/>
                  <v-checkbox :label="'Page Svg'" />
                  <v-checkbox :label="'Fixed'" />
                </div>
                <button class="help" @click="(e) => { helper=!helper }"></button>
              </div>
              <div class="modal__formControl_inside dark_theme">
                <v-tabs v-model="stepper" class="wrap__modal_wfix" :class="{'tab_row' : checkedColumn}">
                  <v-tab data-type="navigation" title="navigation" @click="onCheckTab()"></v-tab>
                  <v-tab data-type="database" title="database" @click="onCheckTab()"></v-tab>
                  <v-tab data-type="canvas" title="canvas" @click="onCheckTab()"></v-tab>
                  <v-tab data-type="table" title="table" @click="onCheckTab()"></v-tab>
                  <v-tab data-type="column" title="column" @click="onCheckTab('column')"></v-tab>
                  <v-tab data-type="style" title="style" @click="onCheckTab('style')"></v-tab>
                  <v-tab data-type="title" title="title" @click="onCheckTab()"></v-tab>
                  <v-tab data-type="note" title="note" @click="onCheckTab()"></v-tab>
                  <div v-show="checkedStyle" class="tab_sub">
                    <div v-for="tab in subStyleTabValid" 
                    :class="{'is_active': tab.value}"
                    :title="tab.text"
                    :key="tab.index"
                    @click="checkSubTab(tab)"
                    >
                      <div :data-sub-summary-quickbtn="`style_${tab.text}`"/>
                    </div>
                  </div>

                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-navigation ref="general" @import-item="onImport" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-database ref="database" @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-canvas :chart="false"/>
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false" >
                    <j-chart-tab-table  @json-updated="onTableJsonUpdated" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false" :class="{'tab_column' : checkedColumn}">
                    <j-chart-tab-column />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-style :mode="currentStyle" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-title @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-note />
                  </v-tab-item>
                </v-tabs>

                <j-chart-table-summary
                  ref="refTableSummary"
                  class="canvasAreaLine"
                  :class="{'has_tab_row' : checkedColumn}"
                  :style="`--area-width: ${this.canvas.CanvasWidth + 1}px; --area-height: ${this.canvas.CanvasHeight + 1}px;`"
                  :ChartType="chartItem.ChartType"

                  :Database="database"
                  :Canvas="canvas"
                  :Table="table"
                  :Styles="styles"
                  :Title="title"
                  :Note="note"

                  :Header="header"
                  :Columns="columns"
                  :Footer="footer"
                  
                  :DataItems="chartData"
                  :ColumnProps="columnProps"
                  
                  @moved="onMoved"
                  @to-xml-string="onXmlString"
                  @column-changed="onResetColumns"
                  @request-action="onRequestedAction"
                />

                <div v-if="!isPreviewChart" id="j-chart-table">
                  <div style="display: inline-block;">
                    <j-data-grid
                      hide-actions
                      :headers="previewHeaders"
                      :items="previewItems"
                    >
                      <template #items="props">
                        <tr :active="props.selected">
                          <td style="text-align: center;">{{ props.item.AREA }}</td>
                          <td style="text-align: center;">{{ props.item.EREC }}</td>
                          <td style="text-align: center;">{{ props.item.PE2 }}</td>
                          <td style="text-align: center;">{{ props.item.PE1 }}</td>
                          <td style="text-align: center;">{{ props.item.PLAN_START }}</td>
                          <td style="text-align: center;">{{ props.item.PLAN_FINISH }}</td>
                          <td style="text-align: center;">{{ props.item.ACT_START }}</td>
                          <td style="text-align: center;">{{ props.item.ACT_FINISH }}</td>
                          <td style="text-align: center;">{{ props.item.DELTA }}</td>
                          <td style="text-align: center;">{{ props.item.PROG }}</td>
                          <td style="text-align: center;">{{ props.item.STAGE }}</td>
                          <td style="text-align: center;">{{ props.item.STATUS }}</td>
                          <td style="text-align: center;">{{ props.item.ICON }}</td>
                        </tr>
                      </template>
                    </j-data-grid>
                  </div>
                </div>

                <div v-if="helper" id="j-chart-helper">
                  <img :src="helperUrl" />
                </div>
                
              </div>
            </v-container>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartTableSummaryDefault'
import ChartModalMixin from '@/mixins/chart.modal.common'
import StaticOptions from '@/mixins/staticOptions'
import JChartTabProps from './SysenvChartlibTableSummary'
import '../../../assets/stylus/ui/component/_sysenv.pagecomp.summary.modal.styl'

export default {
  name: 'sysenv-chartlib-table-summary-modal',
  mixins: [
    ChartModalMixin,
    StaticOptions
  ],
  components: {
    ...JChartTabProps,
  },
  data: () => ({
    previewHeaders: [
      { type: 'text', text: 'AREA', value: 'AREA', sortable: false },
      { type: 'text', text: 'EREC', value: 'EREC', sortable: false },
      { type: 'text', text: 'PE2', value: 'PE2', sortable: false },
      { type: 'text', text: 'PE1', value: 'PE1', sortable: false },
      { type: 'text', text: 'PLAN_START', value: 'PLAN_START', sortable: false },
      { type: 'text', text: 'PLAN_FINISH', value: 'PLAN_FINISH', sortable: false },
      { type: 'text', text: 'ACT_START', value: 'ACT_START', sortable: false },
      { type: 'text', text: 'ACT_FINISH', value: 'ACT_FINISH', sortable: false },
      { type: 'text', text: 'DELTA', value: 'DELTA', sortable: false },
      { type: 'text', text: 'PROG', value: 'PROG', sortable: false },
      { type: 'text', text: 'STAGE', value: 'STAGE', sortable: false },
      { type: 'text', text: 'STATUS', value: 'STATUS', sortable: false },
      { type: 'text', text: 'ICON', value: 'ICON', sortable: false },
    ],
    checkedStyle: false,
    checkedColumn: false,
    // checksubTab: false,
    subStyleTabValid: [
      {text: 'Text', value: true},
      {text: 'Coloring', value: false},
      {text: 'Bar', value: false},
      {text: 'Icon', value: false},
    ],
    idxNum: '',
    subTitle: '',

    prevColUpdated: {}
  }),
  computed: {
    header() {
      // Summary Table Header JSON
      return this.chartItem.Header ? JSON.parse(this.chartItem.Header) : []
    },
    columns() {
      return this.chartItem.Columns ? this.chartItem.Columns : []
    },
    columnProps() { return this.chartItem.JsonProps ? JSON.parse(this.chartItem.JsonProps) : [] },
    currentStyle() {
      return this.subStyleTabValid.find(style => style.value).text
    },
    helperUrl() { return this.chartItem.ChartNo && this.helper ? require(`../../../assets/helpDoc/logo.png`) : '' },
    previewItems() { return this.chartData },
    styles() { return this.chartItem.Style || [] },
    table() { return this.chartItem.Table || {} },
    footer() { return this.chartItem.Footer || [] },
    classTab() {
      if(this.checkedStyle) {
        return 'modal__formControl dark_theme properties14 type3'
      } else {
        return 'modal__formControl dark_theme properties8 type3'
      }
    },
  },
  watch: {
    formOpened(val) {
      if (!val) return

      this.stepper = 0
      this.setEmpty()

      if(this.modeNew) {
        this.setChartItem({
          ChartDb: 'Chart',
          CatCode: __C.CHART.CAT_CODE_TABLE_SUMMARY,
          ChartType: __C.CHART.TYPE_CODE_SINGLE,
          QApplied: 'J',

          ..._ChartDataDefault.Canvas,
          ..._ChartDataDefault.Title,
          ..._ChartDataDefault.Note,

          Style: _ChartDataDefault.Styles,
          Table: _ChartDataDefault.Table,
          Columns: _ChartDataDefault.Columns,
          Footer: _ChartDataDefault.Footer,         // Footer SQL Result
          
          Header: JSON.stringify(_ChartDataDefault.Header),         // Header JSON
          JsonString: JSON.stringify(_ChartDataDefault.DataItems),  // DataItems JSON
        })
        
        this.setChartData(_ChartDataDefault.DataItems)
        this.$refs.general.setData(this.formMode)

        this.onTableJsonUpdated()

      } else {
        this.getChart()
      }
    },
  },
  methods: {
    onChartType(typeName) {
      if (!typeName) return
      this.setChartItem({ ChartType: typeName })
    },
    onRequestedAction(request) {
      console.log(request)
    },
    onResetColumns(val) {
      // if(JSON.stringify(this.prevColUpdated) == JSON.stringify(val)) return
      // this.prevColUpdated = JSON.parse(JSON.stringify(val))
      this.setChartItem({ Columns : val })
    },
    onTableJsonUpdated() {
      setTimeout(() => { 
        this.$refs.refTableSummary.setColumnsWithData() }, 1)
    },
    onCheckTab(val) {
      val == 'style' ? this.checkedStyle = true : this.checkedStyle = false
      val == 'column' ? this.checkedColumn = true : this.checkedColumn = false
    },

    itemIdx(Idx, chartNo) {
      this.idxNum = `(${Idx} / ${chartNo})`
    },
    itemName(val) {
      this.subTitle = val
    },
    checkSubTab(val) {
      this.subStyleTabValid.forEach((v) => {
        v.value = false
      })
      val.value = true
    },
    // CRUD Process
    getChart(code=null) {
      return new Promise(resolve => {
        this.chartLibraryService.getChartTableSummary(code || this.keyValue, res => {
          this.setChartItem(res)
          this.setChartData(
            this.chartItem.QApplied == 'Q' ? (
              this.chartItem.QResultSummary && 
              this.chartItem.QResultSummary.length > 0 ?
              this.chartItem.QResultSummary :
              []
            ) : (
              this.chartItem.JsonString ?
              JSON.parse(this.chartItem.JsonString) :
              []
            )
          )
          this.$refs.general.setData(this.formMode)
          
          this.itemIdx(res.Idx, res.ChartNo)
          this.itemName(res.Name)

          // setTimeout(() => { this.$refs.refTableSummary.setColumnsWithData() })
          
          resolve(res)
        })
      })
    },
    putChart(reqItem, callback) {
      this.chartLibraryService.putChartTableSummary(reqItem, callback)
    },
    updChart(reqItem, callback) {
      this.chartLibraryService.updChartTableSummary(reqItem, callback)
    },
    delChart(chartNo, callback) {
      this.chartLibraryService.delChartTableSummary(chartNo, callback)
    },
  }
}
</script>
