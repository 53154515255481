<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Database</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Preview Mode
        <a class="close" v-on:click="collapsed['preview'] = !collapsed['preview']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['preview']">
        <div class="radio">
          <label v-for="item in previewMode" :key="item.id">
            <input v-model="preview" type="radio" name="previewMode" :value="item.val" />
            {{item.text}}
          </label>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Access Database
        <a class="close" v-on:click="collapsed['access'] = !collapsed['access']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['access']">
        <div class="radio">
          <label v-for="item in databaseAccessOptions" :key="item.id">
            <input v-model="DatabaseAccess" name="DatabaseAccess" type="radio" :value="item.val" @input="(e) => onSave('QApplied', e.target.value)" />
            {{item.text}}
          </label>
        </div>
        <div class="column align_end">
          <div class="column align_end">
            <label class="type_gray alignself_start type_trans20minus">JSON</label>
            <div justify="center">
              <v-btn @click.stop="exp.J=true" class="type_openTextarea">{{ getQueryParts('DatabaseJSON') }}</v-btn>
              <j-modal-scripter 
                v-model="DatabaseJSON" 
                sub-title="JSON Properties/Data"
                title="Access Database"
                :message="validated.DatabaseJSON.message"
                :opened="exp.J"
                :valid="validated.DatabaseJSON.valid"
                @close="exp.J=false;"
                @save="exp.J=false;onSave();"
                @validate="onValidate('DatabaseJSON')"
              />
            </div>
          </div>
          <div class="column align_end">
            <label class="type_gray alignself_start type_trans20minus">SQL Query</label>
            <div justify="center">
              <v-btn @click.stop="exp.Q=true" class="type_openTextarea">{{ getQueryParts('DatabaseQuery') }}</v-btn>
              <j-modal-scripter 
                v-model="DatabaseQuery" 
                sub-title="SQL Query"
                title="Access Database"
                :message="validated.DatabaseQuery.message"
                :opened="exp.Q"
                :valid="validated.DatabaseQuery.valid"
                @close="exp.Q=false;"
                @save="exp.Q=false;onSave();"
                @validate="onValidate('DatabaseQuery')"
              />
            </div>
          </div>
          <div class="column align_end">
            <label class="type_gray alignself_start type_trans20minus">Stored Procedure</label>
            <div justify="center">
              <v-btn @click.stop="exp.S=true" class="type_openTextarea">T-SQL Procedure Script</v-btn>
              <j-modal-scripter 
                v-model="DatabaseSp" 
                sub-title="Stored Procedure"
                title="Access Database"
                :message="validated.DatabaseSp.message"
                :opened="exp.S"
                :valid="validated.DatabaseSp.valid"
                @close="exp.S=false;"
                @save="exp.S=false;onSave();"
                @validate="onValidate('DatabaseSp')"
              />
            </div>
          </div>
        </div>
        <div class="column align_end" style="margin: 1.5rem 0;">
          <div class="column align_end">
            <label class="alignself_start type_trans20minus">Cutoff</label>
            <div justify="center">
              <v-btn @click.stop="exp.C=true" class="type_openTextarea">{{ getQueryParts('QCutoff') }}</v-btn>
              <j-modal-scripter 
                v-model="QCutoff" 
                title="Cutoff Query"
                sub-title="Database Access"
                :buttons="{validate: true, save:false}"
                :message="validated.QCutoff.message"
                :opened="exp.C"
                :valid="validated.QCutoff.valid"
                @close="exp.C=false;"
                @save="exp.C=false;onSave();"
                @validate="onValidate('QCutoff')"
              />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div v-if="useQueries" class="chart_properties_contents">
      <div class="header" style="margin-bottom: 1rem;">
        <div class="row">
          Queries
          <div class="input_flex_wrap code_title_wrapper" style="padding-left: 1rem; font-size: 90%;">
            <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendQuery()">
              <label>Add</label>
            </div>
          </div>
        </div>
        <a class="close" @click="collapsed['queries'] = !collapsed['queries']"></a>
      </div>
      <v-flex class="control">
        <div 
          v-for="(query, i) in localQueries" 
          class="type_common_input"
          style="margin-top: .5rem;"
          :key="query.name"
        >
          <div class="jcon_temp_minus" data-grid-quickbtn="btn" style="margin-top: -2px;" @click="removeQuery(i)" />
          <div>{{ query.name }}</div>
          <div class="row">
            <div justify="center" style="align-items: center">
              <v-btn class="type_openTextarea" @click.stop="expQueries[query.name]=true">{{ getQueryPartsQueries(i) }}</v-btn>
              <j-modal-scripter 
                v-model="query.script" 
                title="Filter Options"
                sub-title="JSON Properties"
                :message="validatedQueries[query.name].message"
                :opened="expQueries[query.name]"
                :valid="validatedQueries[query.name].valid"
                @close="expQueries[query.name]=false;updateQuery()"
                @save="expQueries[query.name]=false;updateQuery();onSave();"
                @validate="onValidateQueries(query.name, query.script)"
              />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Configuration
      </div>
      <v-flex class="control">
        <div class="column align_end">
          <label class="alignself_start">JSON Properties</label>
          <div justify="center">
            <v-btn @click.stop="exp.F=true" class="type_openTextarea">{{ getQueryParts('JsonProps') }}</v-btn>
            <j-modal-scripter 
              v-model="JsonProps" 
              title="Filter Options"
              sub-title="JSON Properties"
              :buttons="{validate: true, save:false}"
              :message="validated.JsonProps.message"
              :opened="exp.F"
              :valid="validated.JsonProps.valid"
              @close="exp.F=false;"
              @save="exp.F=false"
              @validate="onValidate('JsonProps')"
            />
          </div>
        </div>
        <div v-if="modePageSvg" class="type_common_input" style="margin-top: 2.5rem;">
          <span class="label">Use Menu Component</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="useMenuComponent"
                type="radio"
                name="LegendDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div v-if="useMenuComponent=='Y'" class="type_common_select">
          <label>Menu Component</label>
          <select v-model="menuComponentIdx" style="width: 10rem;">
            <option v-for="option in menuComponentOptions" :key="option.index" :value="option.id">{{option.name}}</option>
          </select>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import { ChartLibraryService, SystemCodeService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-database',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  props: {
    config: Boolean,
    modePageSvg: Boolean,
    useQueries: Boolean,
  },
  data: () => ({
    chartLibraryService: null,
    systemCodeService: null,

    collapsed: {
      preview: true,
      access: true,
      import: true,
      queries: false
    },
    expQueries: {},
    exp: {
      J: false,
      Q: false,
      S: false,
      F: false,
      C: false,
    },
    localQueries: [],
    validatedQueries: {},
    validated: {
      DatabaseJSON: { valid: true, message: '' },
      DatabaseQuery: { valid: true, message: '' },
      DatabaseSp: { valid: true, message: '' },
      JsonProps: { valid: true, message: '' },
      QCutoff: { valid: true, message: '' },
    },

    previewMode: [
      { text: "Chart", val: "chart" },
      { text: "Data-Table", val: "table" }
    ],

    menuComponentOptions: [],
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['database']),

    preview: {
      get() { return this.chartItem.preview || 'chart' },
      set(val) { this.setChartItem({ preview: val }) }
    },

    DatabaseAccess: {
      get() { return this.database.DatabaseAccess },
      set(val) { this.setChartItem({ QApplied: val }) }
    },
    DatabaseJSON: {
      get() { return this.database.DatabaseJSON },
      set(val) { this.setChartItem({ JsonString: val }) }
    },
    DatabaseQuery: {
      get() { return this.database.DatabaseQuery },
      set(val) { this.setChartItem({ QSummary: val }) }
    },
    DatabaseSp: {
      get() { return this.database.DatabaseSp },
      set(val) { this.setChartItem({ QSp: val }) }
    },
    JsonProps: {
      get() { return this.chartItem.JsonProps },
      set(val) { this.setChartItem({ JsonProps: val })}
    },
    QCutoff: {
      get() { return this.chartItem.QCutoff },
      set(val) { this.setChartItem({ QCutoff: val })}
    },
    Queries() { return this.chartItem.Queries },

    useMenuComponent: {
      get() { return this.chartItem.MoreAttrs && this.chartItem.MoreAttrs.useMenuComponent ? this.chartItem.MoreAttrs.useMenuComponent : 'N' },
      set(val) { 
        if(!this.chartItem.MoreAttrs) this.setChartItem({ MoreAttrs: {} })

        let moreAttrs_ = JSON.parse(JSON.stringify(this.chartItem.MoreAttrs))
        moreAttrs_.useMenuComponent = val
        this.setChartItem({ MoreAttrs: moreAttrs_ })
      }
    },
    menuComponentIdx: {
      get() { return this.chartItem.MoreAttrs && this.chartItem.MoreAttrs.menuComponentIdx ? this.chartItem.MoreAttrs.menuComponentIdx : 0 },
      set(val) { 
        if(!this.chartItem.MoreAttrs) this.setChartItem({ MoreAttrs: {} })
        
        let moreAttrs_ = JSON.parse(JSON.stringify(this.chartItem.MoreAttrs))
        moreAttrs_.menuComponentIdx = val
        this.setChartItem({ MoreAttrs: moreAttrs_ })
      }
    },
  },
  watch: {
    Queries: {
      handler(val) {
        if(!val) return

        let exp_ = {}
        let validated_ = {}
        val.forEach(q_ => {
          exp_[q_.name] = false
          validated_[q_.name] = { valid: true, message: '' }
        })
        
        this.expQueries = exp_
        this.validatedQueries = validated_
        this.localQueries = JSON.parse(JSON.stringify(val))
      },
      deep: true,
    },
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
    this.systemCodeService = new SystemCodeService()
  },
  mounted() {
    this.systemCodeService.chartOptions(__C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION, 'ALL', res => {
      this.menuComponentOptions = res
    })
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    onSave(name, val) {
      if(!!name) this.setChartItem({ [name]: val })
      this.$emit('save')
    },
    onValidate(typeName) {
      if(['DatabaseJSON', 'JsonProps'].includes(typeName)) {
        this.validated[typeName] = this.validateJson(this[typeName])
        if(this.validated[typeName].valid) this[typeName] = this.validated[typeName].json
      } else {
        this.validateSql(this[typeName]).then(res => {
          this.validated[typeName] = res
        })
      }
    },
    onValidateQueries(name, script) {
      this.validateSql(script).then(res => {
        this.validatedQueries[name] = res
      })
    },


    appendQuery() {
      let queries_ = JSON.parse(JSON.stringify(this.chartItem.Queries))
      let name_ = `SQL${queries_.length + 1}`

      if(queries_.findIndex(q_ => q_.name == name_) >= 0) {
        console.error(`[USER: ] Duplicated query name '${name_}'.`)
        return
      }

      queries_.push({ 
        name: name_,
        script: ''
      })
      this.setChartItem({ Queries: queries_ })
    },
    removeQuery(i) {
      let queries_ = JSON.parse(JSON.stringify(this.chartItem.Queries))
      queries_[i] = null
      queries_ = queries_.filter(q_ => !!q_)

      this.setChartItem({ Queries: queries_ })
    },
    updateQuery() {
      this.setChartItem({ Queries: this.localQueries })
    },

    getQueryPartsQueries(i) {
      return this.chartItem.Queries[i].script.substring(0, 50) + ' ...'
    }
  }
}
</script>
