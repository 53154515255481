<template>
  <div>
    <v-dialog 
      fullscreen 
      hide-overlay 
      transition="slide-full-modal-transition" 
      v-model="formOpened" content-class="j_modal" persistent>
      <v-card>
        <div class="modal__header full_title pd_left_3">
          <button class="hamberger_menu" type="button"  @click="onAction('cancel')">
            <v-icon>mdi-menu</v-icon>
          </button>         
          <span>Format Histogram Chart <span class="sub_title">{{ `${idxNum} ${subTitle}` }}</span></span>
        </div>
        <v-card-text class="modal__formControl type3 properties13 dark_theme">
          <!-- <v-form v-model="valid" ref="form"> -->
            <v-container>
              <v-card-actions class="modal_toolbar">
                <div class="tool_menus">
                  <div>
                    <div
                      data-history-quickbtn="group"
                      title="group"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_S_CURVE ? 'selected': ''"
                      @click="onChartType(__C_.TYPE_CODE_S_CURVE)"
                    ></div>
                  </div>
                  <div>
                    <div
                      data-history-quickbtn="stack"
                      title="stack"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_STACK ? 'selected': ''"
                      @click="onChartType(__C_.TYPE_CODE_STACK)"
                    ></div>
                  </div>
                  <div>
                    <div
                      data-history-quickbtn="rundown"
                      title="rundown"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_RUNDOWN ? 'selected': ''"
                      @click="onChartType(__C_.TYPE_CODE_RUNDOWN)"
                    ></div>
                  </div>
                  <div>
                    <div
                      data-delta-quickbtn="comparison"
                      @click="onChartType(__C_.TYPE_CODE_COMPARE)"
                      title="comparison"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_COMPARE ? 'selected': ''"
                    ></div>
                  </div>
                </div>
              </v-card-actions>
              <div class="modal_dark_btn">
                <j-button
                  class="type01 sky  type_full"
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('save')"
                >Save</j-button>
                <j-button v-if="modeMod" class="type01 delete  type_full" @click="onAction('delete')">Delete</j-button>
                <div class="check_wrapper">
                  <v-checkbox v-model="update" :label="'Update'"/>
                  <v-checkbox :label="'Page Svg'" />
                  <v-checkbox :label="'Fixed'" />
                </div>
                <button class="help" @click="(e) => { helper=!helper }"></button>
              </div>
              <div class="modal__formControl_inside">
                <v-tabs v-model="stepper" class="wrap__modal_wfix">
                  <v-tab data-type="navigation" title="navigation"></v-tab>
                  <v-tab data-type="database" title="database"></v-tab>
                  <v-tab data-type="canvas" title="canvas"></v-tab>
                  <v-tab data-type="timeline" title="timeline"></v-tab>
                  <v-tab data-type="axis" title="axis"></v-tab>
                  <v-tab data-type="text" title="text"></v-tab>
                  <v-tab data-type="line" title="line"></v-tab>
                  <v-tab data-type="bar" title="bar"></v-tab>
                  <v-tab data-type="legend" title="legend"></v-tab>
                  <v-tab data-type="milestone" title="milestone"></v-tab>
                  <v-tab data-type="table" title="table"></v-tab>
                  <v-tab data-type="floatingtable" title="floatingtable"></v-tab>
                  <v-tab data-type="filter" title="filter"></v-tab>

                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-navigation ref="general" @import-item="onImport" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-database ref="database" @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-canvas :chart-space="false" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-timeline />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-axis />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-text use-axis-title @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-line />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-bar :background="false" :chart-name="'history'" :chart-type="chartItem.ChartType" :series="false"/>
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-legend :chart-name="'history'"/>
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-milestone @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-table-summary />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-floating-table />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-filter />
                  </v-tab-item>
                </v-tabs>

                <j-chart-histogram
                  class="canvasAreaLine"
                  :style="`--area-width: ${this.canvas.CanvasWidth + 1}px; --area-height: ${this.canvas.CanvasHeight + 1}px;`"
                  :ChartType="chartItem.ChartType"
                  :Database="database"
                  :Canvas="canvas"
                  :Timeline="timeline"
                  :Axis="localAxis"
                  :Bar="bar"
                  :Line="localLine"
                  :Milestone="milestone"
                  :Legends="legend"
                  :Text="text"
                  :DataItems="chartData"
                  :Cutoff="chartItem.Cutoff"
                  :TableSummary="chartItem.TableSummary"
                  :Values="sqlValues"
                  @moved="onMoved"
                  @to-xml-string="onXmlString"
                />
                <div class="modal__formControl_note">Comment!</div>

                <div v-if="!isPreviewChart" id="j-chart-table">
                  <div style="display: inline-block;">
                    <j-data-grid
                      hide-actions
                      :headers="previewHeaders"
                      :items="previewItems"
                    >
                      <template #items="props">
                        <tr :active="props.selected">
                          <td v-for="h_ in previewHeaders" :key="h_.index" style="text-align: center;">{{ props.item[h_.value] }}</td>
                        </tr>
                      </template>
                    </j-data-grid>
                  </div>
                </div>

                  <div v-if="helper" id="j-chart-helper">
                  <img :src="helperUrl" />
                  </div>
                  
              </div>
            </v-container>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import { mapMutations } from "vuex"

import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartHistogramDefault'
import ChartModalMixin from "@/mixins/chart.modal.common"
import JChartTabProps from './SysenvChartlibHistogram'

export default {
  name: 'sysenv-chartlib-donut-modal',
  mixins: [
    ChartModalMixin
  ],
  components: {
    ...JChartTabProps
  },
  data: () => ({
    previewHeaders: [],
    idxNum: '',
    subTitle: ''
  }),
  computed: {
    helperUrl() { return this.chartItem.ChartNo && this.helper ? require(`../../../assets/helpDoc/logo.png`) : '' },
    localAxis() { return this.chartItem.Axis || {} },
    localLine() { return this.chartItem.Line || {} },
    previewItems() { 
      let items_ = this.chartData.slice(0, 30) 
      this.setPreviewHeader(items_)
      return items_
    },
  },
  watch: {
    formOpened(val) {
      if (!val) return

      this.stepper = 0
      this.setEmpty()

      if(this.modeNew) {
        this.setChartItem({
          ChartDb: 'Chart',
          CatCode: __C.CHART.CAT_CODE_HISTORY,
          ChartType: __C.CHART.TYPE_CODE_S_CURVE,
          JsonString: JSON.stringify(_ChartDataDefault.DataItems),

          ..._ChartDataDefault.Database,
          ..._ChartDataDefault.Canvas,
          ..._ChartDataDefault.Timeline,
          ..._ChartDataDefault.Title,
          ..._ChartDataDefault.Bar,
          ..._ChartDataDefault.Line,
          ..._ChartDataDefault.Legends,
          ..._ChartDataDefault.Milestone,
          ..._ChartDataDefault.Note,

          Axis: _ChartDataDefault.Axis,
          Line: _ChartDataDefault.Line,
          TableSummary: _ChartDataDefault.TableSummary,
          QApplied: 'J',
        })

        this.setChartItem({ 
          MilestoneQApplied: 'J',
          MilestoneQJson: JSON.stringify(_ChartDataDefault.Values.milestone),
        })
        this.setSqlValueMilestone(_ChartDataDefault.Values.milestone)
        this.setSqlValueNote(_ChartDataDefault.Values.note)
        this.setChartData(_ChartDataDefault.DataItems)
        this.$refs.general.setData(this.formMode)

      } else {
        this.getChart()

      }
    }
  },
  methods: {
    itemIdx(Idx, chartNo) {
      this.idxNum = `(${Idx} / ${chartNo})`
    },
    itemName(val) {
      this.subTitle = val
    },
    onChartType(typeName) {
      if (!typeName) return
      this.setChartItem({ ChartType: typeName })
    },

    // CRUD Process
    getChart(code=null) {
      return new Promise(resolve => {
        this.chartLibraryService.getChartHistogram(code || this.keyValue, res => {
          this.setChartItem(res)

          this.setSqlValueMilestone(
            this.chartItem.MilestoneQApplied == 'J' ? 
            (this.chartItem.MilestoneQJson ? JSON.parse(this.chartItem.MilestoneQJson) : []) : 
            this.chartItem.QResultMilestone
          )
          this.setChartData(this.chartItem.QApplied == 'J' ? JSON.parse(this.chartItem.JsonString) : this.chartItem.QResultSummary)
          this.$refs.general.setData(this.formMode)

          this.itemIdx(res.Idx, res.ChartNo)
          this.itemName(res.Name)

          resolve(res)
        })
      })
    },
    putChart(reqItem, callback) {
      this.chartLibraryService.putChartHistogram(reqItem, callback)
    },
    updChart(reqItem, callback) {
      this.chartLibraryService.updChartHistogram(reqItem, callback)
    },
    delChart(chartNo, callback) {
      this.chartLibraryService.delChartHistogram(chartNo, callback)
    },

    setPreviewHeader(items_) {
      let colNames = []
      items_.forEach(item => { colNames = Array.from(new Set([ ...colNames, ...Object.keys(item)])) })
      this.previewHeaders = colNames.map(colName_ => ({ type: 'text', text: colName_, value: colName_, align: 'center', sortable: false }))
    }
  }
}
</script>
